define("accio-alert/pods/components/pdsp-my-subscriptions/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentAccount: Ember.inject.service(),
    realtime: Ember.inject.service(),
    classNames: ['my-subscriptions', 'card-box'],
    init: function init() {
      this._super.apply(this, arguments); // this.get('realtime.lobby').on('dataUpdate', this._dataUpdate);

    },
    _dataUpdate: function _dataUpdate(data) {// if (data.type === 'vehicle') {
      //   this.get('model').reload();
      // }
    }
  });

  _exports.default = _default;
});